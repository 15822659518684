import { compare } from 'compare-versions'
import store from 'store'

export const getLocalStorageInfo = (key: string) => store.get(key)

const PLATFORM_LOCALSTORAGE_KEY = 'platform'

export function isWuAppWebview() {
  if (typeof window !== 'object') return false

  const platformInfo = getLocalStorageInfo(PLATFORM_LOCALSTORAGE_KEY)

  if (platformInfo) {
    const { platform, version } = platformInfo
    if (!['ios', 'android'].includes(platform)) return false

    return compare(version, '3.0.5', '>=')
  }

  return false
}

export function isInPopularAppWebview() {
  if (typeof window !== 'object') return false

  const agent = window.navigator.userAgent.toLowerCase()

  // we haven't found a reliable way to detect all possibilities in webview yet
  // so we just check if in some popular apps
  // reference: https://www.wfublog.com/2018/06/mobile-detect-webview-fb-line-in-app.html
  // reference: https://app.clickup.com/t/3d8d1qp
  return /fban|fbdv|fbav|fb_iab|instagram|line/i.test(agent)
}
