import type { ProductVariantKeyType } from '../../../types/product'

import React, { useMemo, useState } from 'react'

import Used from './components/used'
import RedeemCodeTitle from './components/RedeemCodeTitle'
import LineItemInfoModal from './components/modal'
import ButtonWithGradient from './components/gradient-button'
import { processRedeemCodesToEntries } from './utils'

type MaterialRedeemCodePlan =
  | {
      materialExpiryKind: 'na'
      materialExpiryOptions: null
    }
  | {
      materialExpiryKind: 'exact_time'
      materialExpiryOptions: { endsAt: string }
    }
  | {
      materialExpiryKind: 'period_after_enrolment'
      materialExpiryOptions: { unit: 'months' | 'days'; value: number }
    }

export type RedeemCode = {
  code: string
  useUp: boolean
  canIRedeem: boolean
} & MaterialRedeemCodePlan

export type RedeemCodeButtonActionType = 'gift' | 'self'
export type LineItemInfoProps = {
  locale: string
  orderPaid: boolean
  totalPrice: string
  productTitle: string
  variantKey: ProductVariantKeyType
  redeemCodes?: RedeemCode[]
  renderProductImage: () => React.ReactNode
  handleRedeemCodeButtonClick?: (action: {
    evt?: React.MouseEvent<HTMLButtonElement>
    type: RedeemCodeButtonActionType
    redeemCode: RedeemCode
    closeModal: () => void
  }) => void
  t: any
}

export const LineItemInfo = (props: LineItemInfoProps) => {
  const {
    locale,
    productTitle,
    totalPrice,
    orderPaid,
    variantKey,
    redeemCodes,
    renderProductImage,
    handleRedeemCodeButtonClick,
    t,
  } = props
  const hasRedeemCodes = redeemCodes && redeemCodes.length > 0
  const hasEnrollment =
    variantKey === 'enrollment' || variantKey === 'enrollment_installments'

  return (
    <div style={{ display: 'flex', flexDirection: 'column', fontSize: 12 }}>
      <div style={{ display: 'flex', gap: 14, marginBottom: 4 }}>
        <div style={{ marginRight: '0.75rem' }}>{renderProductImage()}</div>
        <div
          style={{
            color: 'rgb(56, 59, 61)', // text-grayscale-800
            fontSize: '0.875rem',
            lineHeight: '1.25rem', //text-sm
            letterSpacing: '0.025em', //tracking-wide
          }}
        >
          {productTitle}
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontWeight: 400,
          fontSize: '0.875rem',
          lineHeight: '1.25rem', // text-sm
          marginBottom: orderPaid ? 18 : 0,
        }}
      >
        <span style={{ opacity: hasRedeemCodes ? 1 : 0 }}>
          {t('crowdin:common.quantity')}：{redeemCodes?.length}
        </span>
        <span>{totalPrice}</span>
      </div>

      {orderPaid && (
        <>
          {hasRedeemCodes && (
            <RedemptionCodes
              locale={locale}
              redeemCodes={redeemCodes}
              handleClick={handleRedeemCodeButtonClick}
              t={t}
            />
          )}
          {hasEnrollment && <Used t={t} />}
        </>
      )}
    </div>
  )
}

const DEFAULT_MODAL_INFO = { type: '', item: null } as const
const RedemptionCodes = ({
  locale,
  redeemCodes,
  handleClick,
  t,
}: {
  locale: LineItemInfoProps['locale']
  redeemCodes: LineItemInfoProps['redeemCodes']
  handleClick: LineItemInfoProps['handleRedeemCodeButtonClick']
  t: any
}) => {
  if (!redeemCodes) return null
  const [modalInfo, setModalInfo] = useState<{
    type: 'gift' | 'self' | ''
    item: RedeemCode | null
  }>(DEFAULT_MODAL_INFO)
  const handleClose = () => {
    setModalInfo(DEFAULT_MODAL_INFO)
  }
  const processedRedeemCodeEntries = useMemo(
    () => processRedeemCodesToEntries(locale, redeemCodes),
    [redeemCodes]
  )

  return (
    <>
      <LineItemInfoModal
        t={t}
        type={modalInfo.type}
        item={modalInfo.item}
        clickMap={{
          onClose: handleClose,
          onConfirm: async (evt: React.MouseEvent<HTMLButtonElement>) => {
            if (handleClick && modalInfo.item) {
              await handleClick({
                evt,
                type: 'self',
                redeemCode: modalInfo.item,
                closeModal: handleClose,
              })
            }
          },
          onCopy: async (evt: React.MouseEvent<HTMLButtonElement>) => {
            if (handleClick && modalInfo.item) {
              await handleClick({
                evt,
                type: 'gift',
                redeemCode: modalInfo.item,
                closeModal: handleClose,
              })
            }
          },
        }}
      />
      <ul style={{ margin: 0, padding: 0, listStyle: 'none' }}>
        {processedRedeemCodeEntries.map((processedRedeemCodeEntry) => {
          const [titleInfo, redeemCodes] = processedRedeemCodeEntry
          return (
            <li
              key={redeemCodes[0].materialExpiryKind}
              style={{
                marginBottom:
                  processedRedeemCodeEntries.at(-1) === processedRedeemCodeEntry
                    ? 0
                    : 12,
              }}
            >
              <RedeemCodeTitle
                titleInfo={titleInfo}
                redeemCode={redeemCodes[0]}
                t={t}
              />
              {redeemCodes?.map((redeemCode, redeemCodeIdx) => {
                return (
                  <div
                    key={redeemCode.code}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginBottom: redeemCodes.at(-1) === redeemCode ? 0 : 12,
                    }}
                  >
                    <div
                      style={{
                        width: 32,
                        height: 32,
                        backgroundColor: '#EEEFEF', // Grayscale/100
                        marginRight: 13,
                        fontSize: '0.875rem',
                        lineHeight: '1.25rem', // text-sm,
                        color: '#383B3D', //Grayscale/800
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 16,
                      }}
                    >
                      {redeemCodeIdx + 1}
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      {redeemCode.useUp ? (
                        <Used t={t} />
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 12,
                          }}
                        >
                          <ButtonWithGradient
                            isDisabled={!redeemCode.canIRedeem}
                            onClick={() => {
                              setModalInfo({ type: 'self', item: redeemCode })
                            }}
                          >
                            {redeemCode.canIRedeem
                              ? t(
                                  'crowdin:wu-components.line-item-info.use-by-self'
                                )
                              : t(
                                  'crowdin:wu-components.line-item-info.already-have'
                                )}
                          </ButtonWithGradient>
                          <ButtonWithGradient
                            onClick={() => {
                              setModalInfo({ type: 'gift', item: redeemCode })
                              if (handleClick) {
                                handleClick({
                                  type: 'gift',
                                  redeemCode,
                                  closeModal: handleClose,
                                })
                              }
                            }}
                          >
                            {t(
                              'crowdin:wu-components.line-item-info.give-a-gift'
                            )}
                          </ButtonWithGradient>
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
            </li>
          )
        })}
      </ul>
    </>
  )
}
