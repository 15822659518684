import Cookies from 'js-cookie'

import type { Stage } from '../constants'

interface SetCookieParams {
  key: string
  value: string | object
  expires?: number
  domain: string
}

interface ICookieStorage {
  set: (params: SetCookieParams) => void
  get: (
    key: string,
    isJson?: boolean,
    isStageKey?: boolean
  ) => string | Record<string, string> | null
  del: (key: string, options?: object) => void
}

const createInstance = (stage: Stage): ICookieStorage => {
  const stagedKey = (key: string) =>
    `${stage !== 'production' ? `${stage}-` : ''}${key}`

  return {
    set: ({ key, value, expires = 7, domain }) => {
      if (!key || !value) return
      Cookies.set(stagedKey(key), value as string, {
        expires,
        domain,
        sameSite: 'Lax',
      })
    },
    get: (key, isJson = false, isStageKey = true) => {
      const value = Cookies.get(isStageKey ? stagedKey(key) : key)
      if (!value) return null
      if (isJson) return JSON.parse(value)
      return value
    },
    del: (key, options = {}) => {
      Cookies.remove(stagedKey(key), options)
    },
  }
}

export default createInstance
