import React from 'react'
import styled from '@emotion/styled'
import styleVars from '../../theme/themeVar'
import { Base } from './Base'

type CancelButtonProps = React.ComponentProps<typeof Button>

const Cancel = ({ children, ...props }: CancelButtonProps) => {
  return (
    <Button aria-labelledby="cancel button" {...props}>
      {children}
    </Button>
  )
}

const Button = styled(Base)`
  margin: 0.25rem;
  min-height: 2.5rem;
  border-radius: 0.5rem;
  border-width: 0;
  background-color: ${styleVars.color.palette['peach-500']};
  padding: 0.5rem 1.25rem;
  fontsize: ${styleVars.fontSize.m};
  fontweight: 500;
  color: ${styleVars.color.white}};
  box-shadow: none;
  transition: box-shadow 100ms;

  &:hover {
    box-shadow: 0 0 0 3px rgba(100, 150, 200, 0.5);
  }

  &:focus {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  }
`

export { Cancel, CancelButtonProps }
