import styled from '@emotion/styled'
import React from 'react'

interface Props {
  text: string
  icon: any
}

const PageDescription = (props: Props) => {
  const { text, icon } = props
  return (
    <PageDesc>
      <DumplingIcon src={icon} />
      {text}
    </PageDesc>
  )
}

export default PageDescription

const PageDesc = styled.div`
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  margin: 0.5rem auto 1rem;
`

const DumplingIcon = styled.img`
  width: 3.125rem;
  height: 3.125rem;
  margin-right: 0.75rem;
`
