import styled from '@emotion/styled'
import React from 'react'
import { Icon } from '../icon'

import constructLocale, { LangType } from '../../utils/locale-constructor'

import themeVar from '../../theme/themeVar'

const locale = {
  en: {
    suitable_for: {
      title: 'Suitable for',
    },
  },
  'zh-TW': {
    suitable_for: {
      title: '本教材適合',
    },
  },
}
const symbols = ['❶', '❷', '❸', '❹', '❺', '❻', '❼', '❽', '❾']

const { LocaleProvider, useLocaleContext } = constructLocale({
  locales: locale,
})

export interface SuitableForComProps {
  highlights: { type: string; text: string }[]
  suitableFor: { title: string; description: string }[]
  lang: LangType
}

export const SuitableForBlockComp = ({
  suitableFor,
  highlights,
  lang,
}: SuitableForComProps) => {
  const { suitable_for } = useLocaleContext()

  return (
    <LocaleProvider defaultLang={lang}>
      <ContainerWithDivider>
        {highlights && highlights[0].text ? (
          <ThingWillLearnContainer>
            {highlights.map((highlight) => {
              const { type, text } = highlight

              if (type === 'header') {
                return (
                  <ThingWillLearnTitleContainer key={text}>
                    <ThingWillLearnTitle>{text}</ThingWillLearnTitle>
                  </ThingWillLearnTitleContainer>
                )
              }
              return (
                <IconTextContainer key={text}>
                  {text ? (
                    <>
                      <Icon
                        type="check"
                        size={18}
                        style={{
                          color: themeVar.color.palette['primary-500'],
                          marginRight: themeVar.distance.s,
                        }}
                      />
                      <div>{text}</div>
                    </>
                  ) : null}
                </IconTextContainer>
              )
            })}
          </ThingWillLearnContainer>
        ) : null}
        {suitableFor && suitableFor.length > 0 && suitableFor[0].title ? (
          <figcaption
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '0.75rem',
            }}
          >
            <SuitForTitle>{suitable_for.title}</SuitForTitle>

            {suitableFor.map(({ title, description }, idx) => (
              <div key={title} style={{ wordBreak: 'break-word' }}>
                <SuitRowTitle>
                  {symbols[idx]} {title}
                </SuitRowTitle>
                {description && <Description>{description}</Description>}
              </div>
            ))}
          </figcaption>
        ) : null}
      </ContainerWithDivider>
    </LocaleProvider>
  )
}

const ContainerWithDivider = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const ThingWillLearnContainer = styled.figcaption`
  word-break: break-word;
  display: flex;
  flex-direction: column;
  row-gap: ${themeVar.distance.sm};
`

const ThingWillLearnTitleContainer = styled.div`
  margin-bottom: 0;
  &:not(:first-child) {
    margin-top: ${themeVar.distance.xs};
  }
`

const ThingWillLearnTitle = styled.h3`
  font-size: ${themeVar.fontSize.m};
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 1.6px;
  margin: 0;
`

const IconTextContainer = styled.div`
  font-size: ${themeVar.fontSize.sm};
  line-height: 1.5;
  display: flex;
  align-items: center;
`

const SuitForTitle = styled.div`
  font-size: ${themeVar.fontSize.m};
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 1.6px;
  text-transform: capitalize;
`

const SuitRowTitle = styled.div`
  font-size: ${themeVar.fontSize.sm};
  line-height: 1.5;
  letter-spacing: 1.6px;
  font-weight: bold;
  display: flex;
  align-items: center;
`

export const Description = styled.div`
  padding-left: ${themeVar.distance.ml};
  padding-top: ${themeVar.distance.xs};
  font-size: ${themeVar.fontSize.sm};
  line-height: 1.5;
`
