import styled from '@emotion/styled'

const Button = styled.button`
  padding: 8px 0;
  box-shadow: none;
  cursor: pointer;
  border: none;

  width: 100%;
  color: white;
  border-radius: 4px;
  background-color: #72afff;

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
    background-color: #e5e5e5;
  }
`

export default Button
