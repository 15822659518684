import * as React from 'react'

export type LangType = 'zh-TW' | 'en'

type LocaleValueType = string | number | LocaleDataType
type LocaleDataType = { [k: string]: LocaleValueType }

type LocaleType<T> = {
  en: T
  'zh-TW': T
}

interface LocaleProviderPropTypes {
  defaultLang: 'zh-TW' | 'en'
}

function constructLocale<T>({ locales }: { locales: LocaleType<T> }) {
  const LocaleContext = React.createContext(locales['zh-TW'])

  const LocaleProvider: React.FC<LocaleProviderPropTypes> = ({
    defaultLang,
    children,
  }) => {
    const [lang, setLang] = React.useState(defaultLang)

    React.useEffect(() => {
      setLang(defaultLang)
    }, [defaultLang])

    return (
      <LocaleContext.Provider value={locales[lang]}>
        {children}
      </LocaleContext.Provider>
    )
  }

  return {
    LocaleContext: LocaleContext,
    useLocaleContext: () => React.useContext(LocaleContext as React.Context<T>),
    LocaleProvider: LocaleProvider,
  }
}

export default constructLocale
