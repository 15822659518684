export default {
  rwd: {
    phone: '600px',
    overPhone: '601px',
    mobileUp: '768px',
    tablet: '900px',
    desktop: '1150px',
  },
  font: {
    noto: "'Noto Sans TC', 'Noto Sans', sans-serif",
  },
  fontSize: {
    xxl: '28px',
    xl: '24px',
    l: '20px',
    ml: '18px',
    m: '16px',
    sm: '14px',
    s: '12px',
    xs: '10px',
    xxs: '8px',
  },
  color: {
    black: '#000',
    white: '#fff',
    tag: {
      red: '#f06449',
      green: '#16c172',
    },
    blue: {
      master: '#42badb',
      lightMaster: 'rgba(66,186,219,0.1)',
      daylight: '#bbd8ff',
      lightish: '#4272ff',
      progress: '#72AFFF',
      modalBg: '#383b3d',
    },
    brand: {
      fb: '#4867aa',
    },
    bg: {
      lightGrey: '#f2f2f2',
    },
    gray: {
      dark: '#999',
      light: '#eee',
      purple: '#878393',
      lighter: '#f2f2f2',
      charcoal: '#383b3d',
      charcoalAlphaNine: 'rgba(56, 59, 61, 0.9)',
      scale30: '#c6c6c6',
      scale50: '#e5e5e5',
      divider: '#dedfdf',
    },
    purple: {
      dark: '#878393',
    },
    fluorescentPink: '#F29DFC',
    goldenYellow: '#ffc914',
    hardRed: '#ed4747',
    palette: {
      'green-100': '#daf4de',
      'green-300': '#acebc2',
      'green-500': '#16c172',
      'green-700': '#0a8a69',
      'green-900': '#075a54',
      'peach-100': '#feebda',
      'peach-300': '#fbb291',
      'peach-500': '#f06449',
      'peach-700': '#ac2424',
      'peach-900': '#730e1c',
      'yellow-100': '#fffbcc',
      'yellow-300': '#ffed66',
      'yellow-500': '#ffde00',
      'yellow-700': '#bea917',
      'yellow-900': '#75680d',
      'purple-100': '#f8e9ff',
      'purple-300': '#e5bcff',
      'purple-500': '#c890ff',
      'purple-700': '#7548b8',
      'purple-900': '#381b7a',
      'aqua-100': '#ccf0ed',
      'aqua-300': '#8adce2',
      'aqua-500': '#42badb',
      'aqua-700': '#21709d',
      'aqua-900': '#0c3a6a',
      'coral-100': '#feece2',
      'coral-300': '#fcb9a8',
      'coral-500': '#f8736e',
      'coral-700': '#b23847',
      'coral-900': '#771532',
      'primary-100': '#e2f2ff',
      'primary-200': '#c6e6ff',
      'primary-300': '#abd6ff',
      'primary-400': '#94c7fe',
      'primary-500': '#72afff',
      'primary-600': '#5488db',
      'primary-700': '#3a64b8',
      'primary-800': '#244594',
      'primary-900': '#152f79',
      'grayscale-000': '#fff',
      'grayscale-50': '#f4f5f5',
      'grayscale-100': '#eeefef',
      'grayscale-200': '#dedfdf',
      'grayscale-300': '#bdc0c0',
      'grayscale-400': '#9da1a1',
      'grayscale-500': '#7c8282',
      'grayscale-600': '#5c6363',
      'grayscale-700': '#464d4d',
      'grayscale-800': '#383b3d',
      'grayscale-900': '#2a2e2e',
      'grayscale-1000': '#151717',
      'grayscale-1100': '#0e1010',
      'background-light': '#f6f8fc',
      'background-dark': '#151717',
    },
  },
  rounded: {
    s: '4px',
    m: '8px',
    l: '12px',
    xl: '16px',
    full: '9999px',
  },
  distance: {
    xs: '4px',
    s: '8px',
    sm: '12px',
    m: '16px',
    ml: '20px',
    l: '24px',
    xl: '32px',
    xxl: '40px',
  },
  device: {
    s: '(min-width: 600px)',
    m: '(min-width: 900px)',
    l: '(min-width: 1200px)',
    mobileOnly: '(max-width: 599px)',
  },
  spacing: {
    xs: '5px',
    s: '15px',
    m: '30px',
    l: '45px',
    xl: '60px',
  },
  shadow: {
    card: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
    card_m: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
    toggle_btn: '0 0 10px 0 rgba(0, 0, 0, 0.35)',
  },
}
