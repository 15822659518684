export const BUTTON_RESET_STYLE: React.CSSProperties = {
  appearance: "none",
  border: 0,
  outline: 0,
  padding: 0,
  margin: 0,
  borderRadius: 0,
  cursor: "pointer",
  background: "transparent",
}

export const GRADIENT_TEXT_STYLE: React.CSSProperties = {
    background: "linear-gradient(225deg, #72AFFF 0%, #C890FF 100%)", // Gradient/From_top_right/Primary500_purple500
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    color: "transparent",
    WebkitTextFillColor: "transparent",
  }
