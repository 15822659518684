import React from 'react'
import { useForm } from 'react-hook-form'

import Button from '../atoms/button'
import FormControl from '../atoms/form-control'
import Input from '../atoms/input'
import { ResetPasswordFormData } from './index'
import { useLocale } from './local-provider'
import FormTitle from './title'

import { ErrorMessage } from '../atoms'

const defaultValues = {
  password: '',
  confirmPassword: '',
}

interface PropTypes {
  onSubmit: (data: ResetPasswordFormData) => void
}

const ResetPasswordForm = ({ onSubmit }: PropTypes) => {
  const { resetPwd } = useLocale()
  const {
    register,
    formState: { errors, isSubmitting },
    setError,
    handleSubmit,
  } = useForm({
    defaultValues,
  })
  const handleOnSubmit = async (data: ResetPasswordFormData) => {
    const { password, confirmPassword } = data
    if (password !== confirmPassword) {
      return setError('confirmPassword', {
        message: resetPwd.err.pwdNotMatch,
      })
    } else {
      onSubmit(data)
    }
  }

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <FormTitle layout={'reset-password'} />
      <FormControl isInvalid={Boolean(errors.password)}>
        {resetPwd.newPwd.title}
        <Input
          type="password"
          placeholder={resetPwd.newPwd.plhdr}
          {...register('password', {
            required: resetPwd.err.noPwd,
            minLength: {
              value: 6,
              message: resetPwd.err.pwdTooShort || '',
            },
          })}
        />
      </FormControl>
      {errors.password && (
        <ErrorMessage>{errors.password.message}</ErrorMessage>
      )}

      <FormControl isInvalid={Boolean(errors.confirmPassword)}>
        {resetPwd.confirmNewPwd.title}
        <Input
          type="password"
          placeholder={resetPwd.confirmNewPwd.plhdr}
          {...register('confirmPassword', {
            required: resetPwd.err.noPwd,
            minLength: {
              value: 6,
              message: resetPwd.err.pwdTooShort || '',
            },
          })}
        />
      </FormControl>
      {errors.confirmPassword && (
        <ErrorMessage>{errors.confirmPassword.message}</ErrorMessage>
      )}
      <Button
        type="submit"
        disabled={isSubmitting}
        style={{
          fontSize: ' 0.75rem',
          borderRadius: '8px',
          marginTop: '1rem',
        }}
      >
        {isSubmitting ? resetPwd.loading : resetPwd.savePwd}
      </Button>
    </form>
  )
}

export default ResetPasswordForm
