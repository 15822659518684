import styled from '@emotion/styled'
import React from 'react'

import themeVar from '../../theme/themeVar'
import constructLocale, { LangType } from '../../utils/locale-constructor'
import { Icon } from '../icon'

const locale = {
  en: {
    title: 'This course includes:',
  },
  'zh-TW': {
    title: '本產品包含:',
  },
}

const { LocaleProvider, useLocaleContext } = constructLocale({
  locales: locale,
})

type IconTypes =
  | 'list-alt'
  | 'layer-group'
  | 'photo-video'
  | 'pencil-ruler'
  | 'user-friends'
  | 'infinity'

export interface QuantifiedPropTypes {
  factsLabels: { icon: string; text: string }[]
  lang: LangType
}

export const QuantifiedMeasureCard = (props: QuantifiedPropTypes) => {
  const { factsLabels, lang } = props
  const iconSize = 24
  const icons = {
    'list-alt': <Icon type="list_alt" size={iconSize} />,
    'layer-group': <Icon type="layers" size={iconSize} />,
    'photo-video': <Icon type="smart_display" size={iconSize} />,
    'pencil-ruler': <Icon type="design_services" size={iconSize} />,
    'user-friends': <Icon type="group" size={iconSize} />,
    infinity: <Icon type="all_inclusive" size={iconSize} />,
  }
  const { title } = useLocaleContext()

  return (
    <>
      <LocaleProvider defaultLang={lang}>
        <Title>{title}</Title>

        <Container>
          {factsLabels.map(({ icon, text }, index) => (
            <li className="list_item" key={`${text}-${icon}-${index}`}>
              {text ? (
                <>
                  <div className="list_item_icon">
                    {icons[icon as IconTypes]}
                  </div>
                  <span className="list_item_text">{text}</span>
                </>
              ) : null}
            </li>
          ))}
        </Container>
      </LocaleProvider>
    </>
  )
}

const Title = styled.div`
  font-size: ${themeVar.fontSize.m};
  font-weight: bold;
  margin-bottom: ${themeVar.distance.sm};
`

const Container = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  row-gap: ${themeVar.distance.s};

  .list_item {
    display: flex;
    align-items: center;
    row-gap: ${themeVar.distance.xs};
  }
  .list_item_icon {
    display: flex;
    align-items: center;
    width: 30px;
  }
  .list_item_text {
    display: inline-block;
    font-size: ${themeVar.fontSize.sm};
  }
`
