import React from 'react'

import constructLocale, { LangType } from '../../utils/locale-constructor'

import styled from '@emotion/styled'
import themeVar from '../../theme/themeVar'
import { OrderInfoAccordion } from './accordion'

export interface ConvenienceStorePaymentTutorialProps {
  lang: LangType;
}

const locale = {
  en: {
    title: 'Convenience Store Payment Tutorial',
    payment: 'Payment',
    tutorial: 'Tutorial',
  },
  'zh-TW': {
    title: '超商繳費步驟',
    payment: '繳款',
    tutorial: '步驟',
  },
}

const { LocaleProvider, useLocaleContext } = constructLocale({
  locales: locale,
})

export const ConvenienceStorePaymentTutorial = (
  props: ConvenienceStorePaymentTutorialProps
) => {
  const { lang } = props
  return (
    <LocaleProvider defaultLang={lang}>
      <Content />
    </LocaleProvider>
  )
}

const ConvenienceStoresList = [
  {
    name: '7-11',
    paymentMethod: 'ibon',
    imgUrl: 'https://public.wordup.com.tw/7-11_logo.png',
    linkUrl: 'https://www.newebpay.com/info/site_description/seven_ibon_teach',
  },
  {
    name: 'Family-Mart',
    paymentMethod: 'FamiPort',
    imgUrl: 'https://public.wordup.com.tw/family_mart_logo.png',
    linkUrl: 'https://www.newebpay.com/info/site_description/family_teach',
  },
  {
    name: 'Hi-Life',
    paymentMethod: 'Life-ET',
    imgUrl: 'https://public.wordup.com.tw/hi-life_logo.png',
    linkUrl: 'https://www.newebpay.com/info/site_description/hilife_teach',
  },
  {
    name: 'OK-Mart',
    paymentMethod: 'OK-go',
    imgUrl: 'https://public.wordup.com.tw/ok-mart_logo.png',
    linkUrl: 'https://www.newebpay.com/info/site_description/okmart_teach',
  },
]

const Content = () => {
  const { title, payment, tutorial } = useLocaleContext()

  return (
    <OrderInfoAccordion title={title} diamondIconColor="yellow-500">
      <div
        style={{
          fontFamily: themeVar.font.noto,
          letterSpacing: '0.0025em',
          lineHeight: '175%',
          fontSize: themeVar.fontSize.sm,
          fontWeight: 400,
        }}>
        {ConvenienceStoresList.map((item) => (
          <Row key={`${item.name}__${item.paymentMethod}`}>
            <RowLeft>
              <StyledImg src={item.imgUrl} alt={`${item.name} logo`} />{' '}
              <span>
                {item.paymentMethod} {payment}
              </span>
            </RowLeft>

            <StyledLink href={item.linkUrl} target="_blank">
              {tutorial}
            </StyledLink>
          </Row>
        ))}
      </div>
    </OrderInfoAccordion>
  )
}

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${themeVar.distance.sm} 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${themeVar.color.palette['grayscale-100']};
  }

  &:last-child {
    padding-bottom: 0;
  }
`

const RowLeft = styled.div`
  display: flex;
  gap: ${themeVar.distance.s};
  align-items: center;
`

const StyledImg = styled.img`
  width: 80px;
  object-fit: cover;
`

const StyledLink = styled.a`
  color: ${themeVar.color.palette['grayscale-400']} !important;
`
