import styled from '@emotion/styled'
import React from 'react'

import themeVar from '../../theme/themeVar'
import constructLocale, { LangType } from '../../utils/locale-constructor'

import { TitleWithIcon } from './title-with-icon'

export interface NeedHelpProps {
  lang: LangType
}

const commonFaqAfterPurchaseUrl =
  'https://intercom.help/word-up/zh-TW/articles/7201531-%E8%B3%BC%E8%B2%B7%E5%BE%8C%E5%B8%B8%E8%A6%8B%E5%95%8F%E9%A1%8C'

const locale = {
  en: {
    need_help: 'Need Help?',
    if_you_have_any_questions: 'If you have any questions, please visit',
    common_faq_after_purchase: 'Frequently Asked Questions After Purchase',
    to_get_help: 'to get help',
  },
  'zh-TW': {
    need_help: '需要協助嗎？',
    if_you_have_any_questions: '如果您遇到問題，可以前往',
    common_faq_after_purchase: '購買後常見問題',
    to_get_help: '得到幫助哦',
  },
}

const { LocaleProvider, useLocaleContext } = constructLocale({
  locales: locale,
})

export const NeedHelp = (props: NeedHelpProps) => {
  const { lang } = props
  return (
    <LocaleProvider defaultLang={lang}>
      <Content />
    </LocaleProvider>
  )
}

const Content = () => {
  const {
    need_help,
    if_you_have_any_questions,
    common_faq_after_purchase,
    to_get_help,
  } = useLocaleContext()
  return (
    <Container>
      <TitleWithIcon title={need_help} diamondIconColor="primary-500" />

      <TextContainer>
        <span>{if_you_have_any_questions}</span>{' '}
        <a href={commonFaqAfterPurchaseUrl} target="_blank">
          {common_faq_after_purchase}
        </a>{' '}
        <span>{to_get_help}!</span>
      </TextContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeVar.distance.xs};
  width: 100%;
`

const TextContainer = styled.div`
  font-family: ${themeVar.font.noto};
  letter-spacing: 0.0025em;
  line-height: 175%;
  font-size: ${themeVar.fontSize.sm};
  font-weight: 400;

  a {
    color: ${themeVar.color.palette['primary-500']};
  }
`
