import React from 'react'
import { useLocale } from './local-provider'
import Input from '../atoms/input'

interface Props {
  register: any
}

const EmailInput = (props: Props) => {
  const { register } = props
  const { error, email } = useLocale()

  return (
    <Input
      type="email"
      placeholder={email?.plhr}
      {...register('email', {
        required: error?.required,
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          message: error?.email || '',
        },
      })}
    />
  )
}

export default EmailInput
