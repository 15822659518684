import styled from '@emotion/styled'
import React from 'react'
import { useForm } from 'react-hook-form'

import Button from '../atoms/button'
import ErrorMessage from '../atoms/error-message'
import FormControl from '../atoms/form-control'
import { IMAGES } from './images'
import EmailInput from './email-input'
import { ForgetPasswordFormData } from './index'
import { useLocale } from './local-provider'
import PageDescription from './page-description'
import FormTitle from './title'
import { AuthType } from './types'

interface Props {
  onSubmit: (data: ForgetPasswordFormData) => void
  setLayout: (mode: AuthType) => void
}

const defaultValues = {
  email: '',
  password: '',
}

const FogetPasswordLayout = (props: Props) => {
  const { setLayout, onSubmit } = props
  const { forgetPassword } = useLocale()
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues,
  })

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <FormTitle layout={'forget-password'} />

      <PageDescription
        icon={IMAGES.DumplingAvatarWinking}
        text={forgetPassword.pageDesc}
      />

      <FormControl isInvalid={Boolean(errors.email)}>
        <EmailInput register={register} />
      </FormControl>
      {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}

      <SubmitBtn type="submit" disabled={isSubmitting}>
        {forgetPassword.sendEmail}
      </SubmitBtn>

      <ToSignInBtn onClick={() => setLayout('sign-in')}>
        {forgetPassword.toSignIn}
      </ToSignInBtn>
    </Container>
  )
}

export default FogetPasswordLayout

const Container = styled.form`
  display: flex;
  flex-direction: column;
`

const SubmitBtn = styled(Button)`
  margin-top: 1.25rem;
`

const ToSignInBtn = styled.button`
  margin: auto;
  margin-top: 0.5rem;
  color: #72afff;
  font-size: 0.75rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  &:focus {
    border: none;
  }
`
