export const IMAGES = {
  DumplingAvatar:
    'https://public.wordup.com.tw/web-assets/sign-up-log-in/dumpling-avatar-exciting.png',
  DumplingAvatarWinking:
    'https://public.wordup.com.tw/web-assets/sign-up-log-in/dumpling-avatar-winking.png',
  FacebookLogo:
    'https://public.wordup.com.tw/web-assets/sign-up-log-in/facebook-logo.png',
  GoogleLogo:
    'https://public.wordup.com.tw/web-assets/sign-up-log-in/google-logo.png',
}
