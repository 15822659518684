import styled from '@emotion/styled'

const FormControl = styled.label<{ isInvalid?: boolean }>`
  display: block;
  margin-top: 0.75rem;

  span {
    display: inline-block;
    margin-bottom: 0.25rem;
    font-size: 0.75rem;
    font-weight: 500;
  }
`

export default FormControl
