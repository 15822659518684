import styled from '@emotion/styled'
import React, { useMemo } from 'react'

import { useLocale } from './local-provider'
import { AuthType } from './types'

const Title = styled.h3`
  width: 100%;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  text-align: center;
  margin-top: 0;
  margin-bottom: 1rem;
`

interface TitleProps {
  layout: AuthType
}

const FormTitle: React.FC<TitleProps> = ({ layout }) => {
  const { signin, signup, forgetPassword, resetPwd } = useLocale()
  const text = useMemo(() => {
    switch (layout) {
      case 'sign-in':
        return signin.title
      case 'sign-up':
        return signup.title
      case 'forget-password':
        return forgetPassword.title
      case 'reset-password':
        return resetPwd.title
      default:
        return signin.title
    }
  }, [layout])

  return <Title>{text}</Title>
}

export default FormTitle
